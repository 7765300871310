require("jquery");
require("../scss/index.scss");

document.addEventListener("DOMContentLoaded", function () {
  require("./common.js");

  if (document.querySelector("main.template-home")) {
    import("./pages/home.js");
  }
  if (document.querySelector("main.template-work")) {
    import("./pages/work.js");
  }
  if (document.querySelector("main.template-single-project")) {
    import("./pages/single-project.js");
  }
  if (document.querySelector("main.template-about")) {
    import("./pages/about.js");
  }
  if (document.querySelector("main.template-contact")) {
    import("./pages/contact.js");
  }

  // once all scripts are loaded, display body

  setTimeout(() => {
    document.body.classList.remove("hidden");
  }, 1000);

  window.addEventListener("load", (event) => {
    document.body.classList.remove("hidden");
  });
});
