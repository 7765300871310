import("./plugins/contentful-paint");
import("./integrations/gsap-common");

if (document.querySelector(".carousel")) {
  import("./integrations/swiper");
}

if (document.querySelector(".js-mouseanimate-trigger")) {
  import("./plugins/mouseanimate");
}

import("./plugins/hamburger");
